<template>
	<div class="information-box">
		<img class="user" :src="url" alt="">
		<div class="information">
			<p class="name">{{this.$store.state.realnameornot.realname}}</p>
			<p class="authentication" v-if="this.$store.state.realnameornot.caStatus == 0">身份验证：
				<span style="color: #DF2D10;" v-if="this.$store.state.realnameornot.requeststatus == 0">已申请</span>
				<span style="color: #67C23A;" v-if="this.$store.state.realnameornot.requeststatus == 1">已通过</span>
				<span style="color: #DF2D10;" v-if="this.$store.state.realnameornot.requeststatus == 2">认证未通过</span>
				<span style="color: #DF2D10;" v-if="this.$store.state.realnameornot.requeststatus == 3">未认证</span>
			</p>
			<p class="authentication" v-else>身份验证：
				<span style="color: #67C23A;" v-if="this.$store.state.realnameornot.caStatus == 1">已通过</span>
				<span style="color: #DF2D10;" v-if="this.$store.state.realnameornot.caStatus == 2">认证未通过</span>
			</p>
			<p class="number">身份证号：{{ this.$store.state.realnameornot.cardnum && this.$store.state.realnameornot.cardnum.replace(/(.{3}).*(.{1})/, '$1********$2') }}</p>
		</div>
		<div class="but" @click="recertification">重新验证</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				url:''
			}
		},
		created() {
			this.$api.getFile({objectName:this.$store.state.realnameornot.headPic}).then(res=>{
				if(!this.$verification(this.$store.state.realnameornot.headPic)){
					this.$convert( res.data.data.result.url).then((fileUrl) => {
						this.url = fileUrl
						this.$forceUpdate()
					})
				}else{
					this.url = require('@/assets/image/default.png')
				}
			})
		},
		methods: {
			recertification(){
				this.$emit('recertification')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.information-box {
		display: flex;
	}

	.user {
		width: 102PX;
		height: 126PX;
		margin-top: 37PX;
		margin-left: 47PX;
		border-radius: 10PX;
	}

	.information {
		margin-left: 34PX;
		margin-top: 47PX;
	}

	.name {
		font-size: 18PX;
		font-weight: 800;
		color: #333333;
	}

	.authentication {
		font-size: 18PX;
		font-weight: 500;
		color: #333333;
		margin-top: 13PX;
	}

	.number {
		font-size: 18PX;
		font-weight: 500;
		color: #333333;
		margin-top: 13PX;
	}
	.but{
		width: 320.2PX;
		height: 67PX;
		background-image: linear-gradient(to right , #6FB2FF, #095FFF);
		font-size: 24PX;
		font-weight: bold;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 172PX;
		margin-top: 52PX;
		cursor: pointer;
	}
</style>